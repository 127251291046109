import FullWidthLayout from '@/layouts/FullWidthLayout';
import Head from 'next/head';

export default function Custom404() {
  return (
    <FullWidthLayout>
      <Head>
        <title>404: This page could not be found</title>
      </Head>
      <div className="grid h-full place-content-center">
        <div className="py-8 px-4 text-center">
          <h1 className="text-5xl font-bold text-neutral-800">404</h1>
          <p className="mt-3 text-neutral-600">This page could not be found</p>
        </div>
      </div>
    </FullWidthLayout>
  );
}
